import '../assets/styles/Login.css';
import { Input, Form, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
interface UserData {
    users: string[];
}
function Signin(): JSX.Element {
    const [size, setSize] = useState<SizeType>('middle');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [data, setData] = useState<UserData | null>(null);
    const handleFormSubmit = async () => {
        try {
            await form.validateFields();
            navigate('/login');
        } catch (error) {
            console.error('Form validation error:', error);
        }
    };
    const validateReContraseña = (_: any, value: string) => {
        const contraseñaFieldValue = form.getFieldValue('Contraseña');
        if (value !== contraseñaFieldValue) {
            return Promise.reject(new Error('Las contraseñas no coinciden'));
        }
        return Promise.resolve();
    };


    return (
        <Form
            form={form}
            name="control-hooks"
        >
            <Card className="card-iniciar-sesion" bordered={true}>
                Registrarse
                <Form.Item name="Usuario" rules={[{ required: true, message: 'Ingrese su usuario' }]}>
                    <Input
                        placeholder="Usuario"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
                <Form.Item name="Contraseña" rules={[{ required: true, message: 'Ingrese su contraseña' }]}>
                    <Input.Password
                        placeholder="Contraseña"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
                <Form.Item
                    name="ReContraseña"
                    rules={[
                        { required: true, message: 'Ingrese nuevamente su contraseña' },
                        { validator: validateReContraseña }, 
                    ]}
                >
                    <Input.Password
                        placeholder="Repita su contraseña"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
                <Form.Item name="Rol" rules={[{ required: true, message: 'Seleccione el Rol' }]}>
                    <Select
                        placeholder="Rol"
                        style={{ width: 150 }}
                        allowClear
                        options={[{ value: 'ADMIN', label: 'Administrador' }, { value: 'Consultor', label: 'Consultor' }]}
                    />
                </Form.Item>
                <Button type="primary" shape="round" size={size} className="button" htmlType="submit" onClick={handleFormSubmit}>
                    Iniciar
                </Button>

            </Card>
        </Form>
    );
}

export default Signin;
