import '../assets/styles/ContratoDatosResidencial.css';
import { Form, Card} from 'antd';
import { Container } from 'react-bootstrap';

function Agradecimiento(): JSX.Element {
    const [form] = Form.useForm();

    return (
        <Container>
            <Form form={form} name="control-hooks">
                <Card className="card-residen-contrato" bordered={true}>
                    Muchas Gracias
                </Card>
            </Form>
        </Container>
    );
}

export default Agradecimiento;
