import '../assets/styles/BillLoad.css';
import { Form, Card } from 'antd';
import { Container } from 'react-bootstrap';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useState } from 'react';
import { Button, message, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

function BillLoad(): JSX.Element {
    const [size, setSize] = useState<SizeType>('middle');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { Dragger } = Upload;

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        action: 'http://localhost:3001/upload',
        accept: '.pdf',
        beforeUpload: (file) => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
              message.error(`${file.name} No es un archivo pdf`);
            }
            return isPDF || Upload.LIST_IGNORE;
          },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} cargado exitosamente.`);
            } else if (status === 'error') {
                console.error(`${info.file.name} no se pudo cargar.`, info);

                message.error(`${info.file.name} no se pudo cargar.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    const handleFormSubmit = () => {
        const selectedService = localStorage.getItem('selectedService');
        if (selectedService === 'electricidad' || selectedService === 'gas') {
            const selectedUser = localStorage.getItem('selectedUsuario');
            if (selectedUser === 'residencial' || selectedUser === 'comercial') {
                navigate(`/${selectedUser}/${selectedService}`);
            } else {
                console.log('Selected service not found.');
            }
        } else {
            console.log('Selected service not found.');
        }
    };

    return (
        <Container>
            <Form form={form} name="control-hooks">
                <Card className="cardconsult" bordered={true}>
                    <p>Adjunta una factura</p>
                    <Dragger {...props} maxCount={1} className='uploadborder'>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined className='green'/>
                        </p>
                        <p className="ant-upload-text">Arrastra o haz click para subir tu factura</p>
                        <p className="ant-upload-hint">Formato aceptados: PDF</p>
                        </Dragger>
                    <Button type="primary" shape="round" size={size} className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
                        Continuar
                    </Button>
                </Card>
            </Form>
        </Container>
    );
}

export default BillLoad;