import React from "react";
import "../assets/styles/RecommendBox.css";
import { useNavigate } from 'react-router-dom';

export const RecommendBox = (): JSX.Element => {
  const navigate = useNavigate();

  const handleFormSubmit = () => {

                    const selectedUser = localStorage.getItem('selectedUsuario');
                    if (selectedUser === 'residencial' || selectedUser === 'comercial') {
                        navigate(`/${selectedUser}/contrato/cliente`);
                    } else {
                        console.log('Selected service not found.');
                    }
            };


  return (
    <div className="box" onClick={handleFormSubmit}>
        <div className="overlap-group">
          <div className="overlap">
            <div className="text-wrapper">SERVICIO ACTUAL</div>
          </div>
          <div className="div">NOMBRE DE LA EMPRESA</div>
          <div className="text-wrapper-2">+10% ~ +15%</div>
          <div className="text-wrapper-3">Algunos datos</div>
          <div className="text-wrapper-4">...</div>
          <div className="text-wrapper-5">...</div>
          <div className="text-wrapper-6">Algunos datos</div>
          <div className="text-wrapper-7">...</div>
        </div>
      </div>
  );
};

export default RecommendBox;