export const getUserRole = async (userId: string) => {
    try {
      const response = await fetch(`http://localhost:3001/auth/role/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user role');
      }
      const data = await response.json();
      console.log("role", data);
      return data.role;
    } catch (error) {
      console.error('Error fetching user role:', error);
      return null;
    }
  };
  
  export const getUserId = async (userName: string) => {
    try {
      const response = await fetch(`http://localhost:3001/auth/id/${userName}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user id');
      }
      const data = await response.json();
      console.log("id", data);
      return data.id;
    } catch (error) {
      console.error('Error fetching user id:', error);
      return null;
    }
  };