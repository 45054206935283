import '../assets/styles/Navbar.css'
import {Row, Col } from 'antd';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/img/logo.png'
import { Link } from 'react-router-dom';

function NavbarComp(): JSX.Element {
  return (
      <Navbar className="bg-body-tertiary navbar" expand="md">
            <Col>
              <Link to="/">
                <img
                  alt=""
                  src={logo}
                  width="50"
                  height="50"
                  className="d-inline-block align-top logo-container"
                />
              </Link>
            </Col>
            <Col className="title-col">
              <Link to="/" >
                <a className="title">Menor Consumo</a>
              </Link>
            </Col>
      </Navbar>
  );
}
export default NavbarComp;