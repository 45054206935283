import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { getUserRole } from '../services/UserServices';
import { getUserId } from '../services/UserServices';
import Loading from '../components/Loading'
interface SigninRouteProps {
  children: ReactNode;
  requiredRole: string;
}

const SigninRoute: React.FC<SigninRouteProps> = ({ requiredRole , children }) => {
  const [Tokencookies] = useCookies(['access-token']);
  const [Usercookies] = useCookies(['UserName']);
  const isLoggedIn = !!Tokencookies['access-token'];
  const username = Usercookies.UserName;
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = await getUserId(username);
        setUserId(id);
        const role = await getUserRole(id);
        setUserRole(role);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, [username]);

  if (loading) {
    return <Loading />;
  }

  const canActivate = isLoggedIn && userRole === requiredRole;

  return canActivate ? (children as React.ReactElement) : <Navigate to='/404' />;
};

export default SigninRoute;