import '../assets/styles/Consult.css';
import {  Card, Col, Row } from 'antd';
import { Container } from 'react-bootstrap';

function GasConsult(): JSX.Element {

  return (
    <Container>
      <Row className='alignmentall'>
        <Col className='col-pad'>
          <Card className='card-pad' bordered={true}>
              <p>gasgasgasgasgasgasgasgass</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
          </Card>
          <Card className='card-pad' bordered={true}>
          <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
          </Card>
        </Col>
        <Col className='col-pad'>
          <Card className='card-pad' bordered={true}>
          <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
          </Card>
          <Card className='card-pad' bordered={true}>
          <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
              <p>aaaaaaaaaaaaaaaaaaaaaaaaa</p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GasConsult;