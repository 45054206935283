import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [cookies] = useCookies(['Token']);
  const isLoggedIn = !!cookies.Token;

  return isLoggedIn ? (children as React.ReactElement) : <Navigate to='/404' />;
};

export default PrivateRoute;