import '../assets/styles/CargarDatos2TD.css';
import { Form, Card, Col, Row, Popover} from 'antd';
import { Container } from 'react-bootstrap';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
interface ChargeRow {
  rowKey: number;
  value: string;
  CargoNormAmount?: string;
}
interface DiscountRow {
  rowKey: number;
  value: string;
  DiscountAmount?: string;
}
interface ServicesAndOthersRow {
  rowKey: number;
  value: string;
}
function CargarDatos2TD(): JSX.Element {

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [discountRows, setDiscountRows] = useState<DiscountRow[]>([]);
  const [chargeRows, setChargeRows] = useState<ChargeRow[]>([]);
  const [ServicesAndOthersRows, setServicesAndOthersRows] = useState<ServicesAndOthersRow[]>([]);
  const [showExcessButtons, setShowExcessButtons] = useState(false);
  const [selectedOptionExcess, setSelectedOptionExcess] = useState<number | null>(null);
  const [showReactive, setShowReactive] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [messageDisounts, setmessageDisounts] = useState(false);
  const [messageExess, setmessageExess] = useState(false);
  const [messageReactive, setmessageReactive] = useState(false);
  const [messageOthers, setmessageOthers] = useState(false);
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [ivaValue, setIvaValue] = useState(0);

  const [equipodemedidaprice, setequipodemedidaprice] = useState<number | undefined>(undefined);

  const [powerPunta, setPowerPunta] = useState('');
  const [powerValle, setPowerValle] = useState('');
  const [powerConsumida1, setPowerConsumida1] = useState('');
  const [powerConsumida2, setPowerConsumida2] = useState('');
  const [powerConsumida3, setPowerConsumida3] = useState('');
  const [powerExesos1, setPowerExesos1] = useState('');
  const [powerExesos2, setPowerExesos2] = useState('');
  const [powerExesos3, setPowerExesos3] = useState('');
  const [powerReactiva1, setPowerReactiva1] = useState('');

  const [ratePunta, setRatePunta] = useState('');
  const [rateValle, setRateValle] = useState('');
  const [rateConsumida1, setRateConsumida1] = useState('');
  const [rateConsumida2, setRateConsumida2] = useState('');
  const [rateConsumida3, setRateConsumida3] = useState('');
  const [rateExesos1, setRateExesos1] = useState('');
  const [rateExesos2, setRateExesos2] = useState('');
  const [rateExesos3, setRateExesos3] = useState('');
  const [rateReactiva1, setRateReactiva1] = useState('');

  const [resultPunta, setResultPunta] = useState<string | null>(null);
  const [resultValle, setResultValle] = useState<string | null>(null);
  const [resultConsumida1, setResultConsumida1] = useState<string | null>(null);
  const [resultConsumida2, setResultConsumida2] = useState<string | null>(null);
  const [resultConsumida3, setResultConsumida3] = useState<string | null>(null);
  const [resultExesos1, setResultExesos1] = useState<string | null>(null);
  const [resultExesos2, setResultExesos2] = useState<string | null>(null);
  const [resultExesos3, setResultExesos3] = useState<string | null>(null);
  const [resultReactiva1, setResultReactiva1] = useState<string | null>(null);

  const handleequipodemedidapriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(e.target.value);
    if (!isNaN(inputValue)) {
      setequipodemedidaprice(inputValue);
    };
  };

  const handleInputChange = (fieldName: string, value: string) => {
    form.setFieldsValue({ [fieldName]: value });
    const formValues = form.getFieldsValue();
    localStorage.setItem('formDataCarga2.0', JSON.stringify(formValues));
  };

  const updateResult = (
    power: string,
    rate: string,
    setResult: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    const result = (parseFloat(power) || 0) * (parseFloat(rate) || 0);
    setResult(result.toFixed(2));
  };
  const IVA = (puntaValue: string, valleValue: string): number => {
    const selectedUser = localStorage.getItem('selectedUsuario');
    if (selectedUser === 'residencial') {
      const threshold = 10;
      const lowerIVA = 10;
      const higherIVA = 21;
      const puntaNumeric = parseFloat(puntaValue);
      const valleNumeric = parseFloat(valleValue);
      if (isNaN(puntaNumeric) || isNaN(valleNumeric)) {
        return 10;
      }
      const maxInputValue = Math.max(puntaNumeric, valleNumeric);
      const iva = maxInputValue <= threshold ? lowerIVA : higherIVA;
      return iva;
    } else if (selectedUser === 'comercial') {
      const iva = 21;
      return iva;
    }
    return 10;
  };
  const handlePowerChangePuntaAndValle = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.value;

    if (name === 'powerPunta') {
      setPowerPunta(value);
      const ivaValue = IVA(value, powerValle);
      setIvaValue(ivaValue);
      handleInputChange('powerPunta', value);
      calculateResult(value, ratePunta, setRatePunta, setResultPunta, 'ratePunta');
    } else if (name === 'powerValle') {
      setPowerValle(value);
      const ivaValue = IVA(powerPunta, value);
      setIvaValue(ivaValue);
      handleInputChange('powerValle', value);
      calculateResult(value, rateValle, setRateValle, setResultValle, 'rateValle');
    }
  };
  const calculateResult = (
    power: string,
    rate: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    const calculatedResult = (Number(differenceInDays) * Number(power)) * Number(rate);
    const resultString = calculatedResult.toString();
    setRate(rate);
    setResult(resultString);
    handleInputChange(fieldName, rate);
  };
  const handleRateChangeForPuntaandValle = (
    e: React.ChangeEvent<HTMLInputElement>,
    power: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    const newRate = e.target.value;
    calculateResult(power, newRate, setRate, setResult, fieldName);
  };
  const handlePowerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setPower: React.Dispatch<React.SetStateAction<string>>,
    rate: string,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    setPower(e.target.value);
    updateResult(e.target.value, rate, setResult);
    handleInputChange(fieldName, e.target.value);
  };
  const handleRateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    power: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    setRate(e.target.value);
    updateResult(power, e.target.value, setResult);
    handleInputChange(fieldName, e.target.value);
  };
  const handleOptionButtonClickExcess = (option: number) => {

    setSelectedOptionExcess(option);

    setPowerExesos1("0");
    setRateExesos1("0");
    setResultExesos1("0");

    setPowerExesos2("0");
    setRateExesos2("0");
    setResultExesos2("0");

    setPowerExesos3("0");
    setRateExesos3("0");
    setResultExesos3("0");

    form.resetFields(["ExesosPower1", "ExesosRate1", "ExesosPower2", "ExesosRate2", "ExesosPower3", "ExesosRate3"]);
  };
  const handlePlusButtonClickExcess = () => {
    setmessageExess(false)
    setmessageReactive(true)
    setShowExcessButtons(true);
  };
  const handleLessButtonClickExcess = () => {
    setShowExcessButtons(false);
    setSelectedOptionExcess(null);
    setResultExesos1("0");
    setResultExesos2("0");
    setResultExesos3("0");
    setPowerExesos1("0");
    setPowerExesos2("0");
    setPowerExesos3("0");
    setRateExesos1("0");
    setRateExesos2("0");
    setRateExesos3("0");
  };
  const handlePlusButtonClickReactive = () => {
    setmessageReactive(false);
    setmessageOthers(true)
    setShowReactive(true);
  };
  const handleLessButtonClickReactive = () => {
    setShowReactive(false);
    setPowerReactiva1("0");
    setResultReactiva1("0");
    setRateReactiva1("0");
  };
  const renderEnergiaConsumida = () => {
    if (selectedButton === 1) {

      return (
        <>
          <Form.Item
            name="consumida1Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida1, rateConsumida1, setResultConsumida1, 'consumida1Power')} /> kWh </a>
          </Form.Item>
          <Form.Item
            name="consumida1Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>&nbsp; x {" "}
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) => {
                  handleRateChange(e, powerConsumida1, setRateConsumida1, setResultConsumida1, 'consumida1Rate');
                  setmessageDisounts(true);
                }} />€/kWh </a>
          </Form.Item>
        </>
      );
    } else if (selectedButton === 3) {
      return (
        <>
          <Form.Item
            name="consumida1Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              P 1:{" "}
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida1, rateConsumida1, setResultConsumida1, 'consumida1Power')} /> kWh x &nbsp;</a>
          </Form.Item>
          <Form.Item
            name="consumida1Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) => {
                  handleRateChange(e, powerConsumida1, setRateConsumida1, setResultConsumida1, 'consumida1Rate')
                }} />€/kWh</a></Form.Item>
        </>
      );
    }
    return "";
  };
  const handleAddDiscountRow = (): void => {
    setmessageDisounts(false)
    setmessageExess(true)
    if (discountRows.length < 1) {
      setDiscountRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
    }
  };

  const handleAddServicesAndOthersRow = (): void => {
    setmessageOthers(false)
    if (ServicesAndOthersRows.length < 1) {
      setServicesAndOthersRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
    }
  };
  const handleDeleteDiscountRow = (rowKey: number): void => {
    setDiscountRows((prevRows) => prevRows.filter((row) => row.rowKey !== rowKey));
  };

  const handleDiscountInputChange = (rowKey: number, inputValue: string, inputType: string): void => {
    setDiscountRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.rowKey === rowKey ? { ...row, [inputType]: inputValue } : row
      );
      const fieldName = `discountInput_${rowKey}_${inputType}`;
      handleInputChange(fieldName, inputValue);

      return updatedRows;
    });
  };
  const handleServicesAndOthersInputChange = (rowKey: number, inputValue: string): void => {
    setServicesAndOthersRows((prevRows) => {
      return prevRows.map((row) =>
        row.rowKey === rowKey ? { ...row, value: inputValue } : row
      );
    });
  };
  const handleDeleteServicesAndOthersRow = (rowKey: number) => {
    setServicesAndOthersRows((prevRows) => prevRows.filter((row) => row.rowKey !== rowKey));
    form.setFieldsValue({
      [`ServiceAndOthersDescription_${rowKey}`]: undefined,
      [`ServiceAndOthersAmount_${rowKey}`]: undefined,
    });
  };
  const handleButtonClick = (buttonNumber: number) => {
    setSelectedButton(buttonNumber);
    form.setFieldsValue({
      'Periodos Facturados': buttonNumber,
    });
    setResultConsumida1("0");
    setResultConsumida2("0");
    setResultConsumida3("0");
    setPowerConsumida1("0");
    setPowerConsumida2("0");
    setPowerConsumida3("0");
    setRateConsumida1("0");
    setRateConsumida2("0");
    setRateConsumida3("0");
  };

  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      localStorage.setItem('formDataCarga2.0', JSON.stringify(formValues));
      navigate('/recomendacion');
    } catch (errorInfo) {
      console.error('Form incomplete:', errorInfo);
    }
  };
  const tienediscount = (
    <div className='divguide'>
      <p>Tiene algun descuento?
        <button className='bottonno' onClick={() => {
          setmessageDisounts(false)
          setmessageExess(true)
        }}>No</button>
      </p>
    </div>
  );

  const tieneexess = (
    <div className='divguide'>
      <p>Tiene algun exeso?
        <button className='bottonno' onClick={() => {
          setmessageExess(false)
          setmessageReactive(true)
        }
        }>No</button>
      </p>
    </div>
  );
  const tienereactive = (
    <div className='divguide'>
      <p>Tiene energía reactiva?
        <button className='bottonno' onClick={() => {
          setmessageReactive(false)
          setmessageOthers(true)
        }}>No</button>
      </p>
    </div>
  );
  const tieneother = (
    <div className='divguide'>
      <p>Tiene otros conceptos?
        <button className='bottonno' onClick={() => {
          setmessageOthers(false)
        }}>No</button>
      </p>
    </div>
  );
  const potenciafacturadacontent = (
    <div className='divguide'>
      <p>Número de kilovatios de los que dispones en tu suministro.</p>
    </div>
  );
  const periodosfacturadoscontent = (
    <div className='divguide'>
      <p>Tramos en los que se divide el consumo de energía eléctrica.</p>
    </div>
  );
  const exesoscontent = (
    <div className='divguide'>
      <p>Cargo a pagar cuando el consumidor supera la potencia eléctrica contratada.</p>
    </div>
  );
  const reactivacontent = (
    <div className='divguide'>
      <p>Tipo de energía eléctrica que se absorbe de la red y luego se devuelve.</p>
    </div>
  );
  const bonosocialcontent = (
    <div className='divguide'>
      <p>Mecanismo de descuento fijado por el Gobierno de España. </p>
    </div>
  );
  const impuestocontent = (
    <div className='divguide'>
      <p>Impuesto especial que grava el coste de la fabricación de electricidad.</p>
    </div>
  );
  const alquilerquiposcontent = (
    <div className='divguide'>
      <p>Cantidad mensual que se pagar cuando el contador es propiedad de la empresa.</p>
    </div>
  );
  const ivacontent = (
    <div className='divguide'>
      <p>El Impuesto sobre el Valor Añadido constituye la base del sistema español de imposición indirecta.</p>
    </div>
  );
  const contentguide = (
    <div className='divguide'>
      <p>Seleccione una opción.</p>
    </div>
  );
  const additionalRows = selectedButton === 3 ? (
    <>
      <Row className='verde'>
        <Col xs={0} lg={8} xl={8} className='contentleft'>
        </Col>
        <Col xs={18} lg={10} xl={10} className='contentleft'>

          <Form.Item
            name="consumida2Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold paddingmiddletoright'>
              P 2: {" "}
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida2, rateConsumida2, setResultConsumida2, 'consumida2Power')}></input> kWh
              x &nbsp;</a>
          </Form.Item>
          <Form.Item
            name="consumida2Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handleRateChange(e, powerConsumida2, setRateConsumida2, setResultConsumida2, 'consumida2Rate')}></input>€/kWh</a>
          </Form.Item>
        </Col>
        <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
          <a className='nobold'>{resultConsumida2 !== null ? `${resultConsumida2}€` : '___€'}</a>
        </Col>
      </Row>
      <Row className='verde'>
        <Col xs={0} sm={0} lg={8} xl={8} className='contentleft'>
        </Col>
        <Col xs={18} lg={10} xl={10} className='contentleft'>
          <Form.Item
            name="consumida3Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold paddingmiddletoright'>P 3: {" "}
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida3, rateConsumida3, setResultConsumida3, 'consumida3Power')}></input> kWh
              x &nbsp;</a>
          </Form.Item>
          <Form.Item
            name="consumida3Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) => {
                  handleRateChange(e, powerConsumida3, setRateConsumida3, setResultConsumida3, 'consumida3Rate');
                  setmessageDisounts(true)
                }
                }></input>€/kWh</a>
          </Form.Item>
        </Col>
        <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
          <a className='nobold'>{resultConsumida3 !== null ? `${resultConsumida3}€` : '___€'}</a>
        </Col>
      </Row>
    </>
  ) : null;
  const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
    console.log('Failed:', errorInfo);
    setErrorModalVisible(true);
  };
  const handleModalOk = () => {
    setErrorModalVisible(false);
  };

  const updateTotalSum = () => {
    const valuesToSum = [
      resultPunta, resultValle, resultConsumida1, resultConsumida2, resultConsumida3,
      resultExesos1, resultExesos2, resultExesos3, resultReactiva1
    ];
    const filteredValues = valuesToSum
      .filter(value => value !== null)
      .map(value => parseFloat(value as string))
      .filter(value => !isNaN(value));

    const sum = filteredValues.reduce((acc, currentValue) => acc + currentValue, 0);
    setTotalSum(sum);
  };
  const calculateTotal = (): number => {
    const totalWithoutDiscount = chargeRows.reduce((total, row) => {
      const amount = parseFloat(row.CargoNormAmount || '0');
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    const discountTotal = discountRows.reduce((total, row) => {
      const amount = parseFloat(row.DiscountAmount || '0');
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    const additionalTotalSum = totalSum || 0;
    const bonosocialfijo = differenceInDays * 0.038455 || 0;
    const rawTotal = totalWithoutDiscount - discountTotal + additionalTotalSum + bonosocialfijo;
    const formattedTotal = Math.max(parseFloat(rawTotal.toFixed(2)), 0);
    return formattedTotal;
  };
  const calculateTotalOthers = (): number => {
    const totalOthers = ServicesAndOthersRows.reduce((total, row) => total + parseFloat(row.value) || 0, 0);
    return totalOthers
  };
  useEffect(() => {
    updateTotalSum();
  }, [resultPunta, resultValle, resultConsumida1, resultConsumida2, resultConsumida3,
    resultExesos1, resultExesos2, resultExesos3, resultReactiva1]);
  useEffect(() => {
    const selectedUsuario = localStorage.getItem('selectedUsuario')
    if (selectedUsuario == 'residencial') {
      const storedDataStringRes = localStorage.getItem('formDataElectricidadCarga Residencial');
      if (storedDataStringRes) {
        const storedData = JSON.parse(storedDataStringRes);
        const storedInicio = new Date(storedData.Inicio);
        const storedFin = new Date(storedData.final);
        const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
        const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        setDifferenceInDays(differenceInDays);
      }
    } else if (selectedUsuario == 'comercial') {
      const storedDataStringCom = localStorage.getItem('formDataElectricidadCarga Comercial');
      if (storedDataStringCom) {
        const storedData = JSON.parse(storedDataStringCom);
        const storedInicio = new Date(storedData.Inicio);
        const storedFin = new Date(storedData.final);
        const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
        const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        setDifferenceInDays(differenceInDays);
      }
    }
  }, []);
  useEffect(() => {
    try {
      const storedFormData = localStorage.getItem('formDataCarga2.0');
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
        // form.setFieldsValue(parsedFormData);
      }
    } catch (error) {
      console.error('Error parsing stored form data:', error);
    }
  }, [form]);

  return (
    <Container>
      <Form form={form} name="control-hooks" onFinishFailed={onFinishFailed}
      >
        <Card className="cardtable" bordered={true}>
          <Col>
            <Row className='contenttocenter paddbottomtitle'>
              <Col>
                <a className="titlecard">Busca tu factura y completa el siguiente formulario</a>
              </Col>
            </Row>
            <Row className='verde'>
              <a className='negrita paddtextleft'>Energía</a>
            </Row>
            <Row className='blanco'>
              <Col xs={24} sm={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Potencia facturada:</a>
              </Col>


              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="PuntaPower"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddtextleft'>
                    Punta:{" "}
                    <input
                      type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      value={powerPunta}
                      onChange={(e) => handlePowerChangePuntaAndValle(e, 'powerPunta')}
                    /> kW
                  </a>
                </Form.Item>
                <Form.Item
                  name="PuntaRate"
                  className='height inlineinput'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold '>
                    &nbsp; x {differenceInDays} días x{" "}
                    <input
                      type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleRateChangeForPuntaandValle(e, powerPunta, setRatePunta, setResultPunta, 'ratePunta')}
                    />€/kW día
                  </a>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} lg={6} xl={6} className='contentright'>
                <a className='nobold'>{resultPunta !== null ? `${resultPunta}€` : '0€'}</a>
                <Popover content={potenciafacturadacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>

            <Row className='verde'>
              <Col xs={0} md={0} lg={8} xl={8} className='contentleft'>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="VallePower"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddtextleft'>Valle: {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      value={powerValle}
                      onChange={(e) => handlePowerChangePuntaAndValle(e, 'powerValle')}
                    ></input> kW </a>
                </Form.Item>
                <Form.Item
                  name="ValleRate"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> &nbsp; x {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) =>
                        handleRateChangeForPuntaandValle(e, powerValle, setRateValle, setResultValle, 'rateValle')
                      }
                    ></input>€/kW día</a>
                </Form.Item>
              </Col>
              <Col xs={21} md={24} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='nobold'>{resultValle !== null ? `${resultValle}€` : '0€'}</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <a className='negrita paddtextleft'>Energía consumida</a>
            </Row>
            <Row className={`verde `}>
              <Col xs={24} sm={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Periodos facturados: </a>
                <Form.Item
                  name="Periodos Facturados"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}

                >
                  <Popover content={contentguide} visible={!selectedButton} placement="right">
                    <Button
                      className={`roundedbutton smaller-button ${selectedButton === 1 ? 'selected' : ''} ${selectedButton ? '' : 'no-button-selected'}`}
                      onClick={() => handleButtonClick(1)}
                    >
                      1
                    </Button>
                    <a className='nobold'>  ó  </a>
                    <Button
                      className={`roundedbutton smaller-button ${selectedButton === 3 ? 'selected' : ''} ${selectedButton ? '' : 'no-button-selected'}`}
                      onClick={() => handleButtonClick(3)}
                    >
                      3
                    </Button>
                  </Popover>

                </Form.Item>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'>{renderEnergiaConsumida()}</a>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                {(selectedButton === 1 || selectedButton === 3) && (<a className='nobold'>{resultConsumida1 !== null ? `${resultConsumida1}€` : '0€'}</a>)}
                <Popover content={periodosfacturadoscontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            {additionalRows}
            <Row className='blanco'>
              <Col xs={24} sm={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tienediscount} visible={messageDisounts} placement="right">
                  <a className='negrita paddtextleft'>Descuentos   </a>
                  <Button
                    className={`roundedbutton smaller-button`}
                    onClick={handleAddDiscountRow}
                  >
                    +
                  </Button>
                </Popover>
              </Col>
              <Col xs={0} sm={0} lg={10} xl={10}>
              </Col>
              <Col xs={0} sm={0} lg={6} xl={6} className='contentright'>
              </Col>
            </Row>
            {discountRows.map((row) => (
              <Row key={row.rowKey}
                className='blanco'
              >
                <Col xs={15} lg={8} xl={8} className='contentleft'>
                  <a className='nobold paddtextleft'>Monto total en Euros: {""} </a>
                </Col>
                <Col xs={0} sm={0} lg={10} xl={10} className='contentleft'>

                </Col>
                <Col xs={8} lg={6} xl={6} className='contentright'>
                  <Form.Item
                    name={`DiscountAmount_${row.rowKey}`}
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  >
                    <a className='nobold paddtextright'>-<input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) => {
                        handleDiscountInputChange(row.rowKey, e.target.value, 'DiscountAmount')
                      }}
                    ></input>€ </a>
                  </Form.Item>
                  <Button
                    className={`roundedbutton smaller-button paddtextrightnoquestion`}
                    onClick={() => handleDeleteDiscountRow(row.rowKey)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}
            <Row className='verde'>
              <Col xs={24} sm={15} lg={8} xl={8} className='contentleft'>
                <Popover content={tieneexess} visible={messageExess} placement="bottom">
                  <a className='negrita paddtextleft'>Excesos de Potencia:   </a>
                  <Button className={`roundedbutton smaller-button`} onClick={handlePlusButtonClickExcess}> + </Button>
                </Popover>
              </Col>
              <Col xs={19} sm={24} lg={10} xl={10} className='contentleft'>

                {selectedOptionExcess === 1
                  ? <>

                    <Form.Item
                      name="ExesosPower1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    ><a className='nobold paddingmiddletoright'>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                          onChange={(e) =>
                            handlePowerChange(e, setPowerExesos1, rateExesos1, setResultExesos1, 'Exesos1Power')
                          } /> kW x &nbsp;</a>
                    </Form.Item>
                    <Form.Item
                      name="ExesosRate1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    >
                      <a className='nobold '>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                          onChange={(e) =>
                            handleRateChange(e, powerExesos1, setRateExesos1, setResultExesos1, 'Exesos1Rate')
                          } />€/kW día</a></Form.Item>
                  </>
                  : selectedOptionExcess === 2 || selectedOptionExcess === 3
                    ? <>
                      <Form.Item
                        name="ExesosPower1"
                        className='height'
                        rules={[
                          { required: true, message: '' },
                        ]}
                      ><a className='nobold paddingmiddletoright'>
                          P 1: {" "}
                          <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                            onChange={(e) =>
                              handlePowerChange(e, setPowerExesos1, rateExesos1, setResultExesos1, 'Exesos1Power')
                            } /> kW x &nbsp;</a>
                      </Form.Item>
                      <Form.Item
                        name="ExesosRate1"
                        className='height'
                        rules={[
                          { required: true, message: '' },
                        ]}
                      >
                        <a className='nobold '>
                          <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                            onChange={(e) =>
                              handleRateChange(e, powerExesos1, setRateExesos1, setResultExesos1, 'Exesos1Rate')
                            } />€/kW día</a>
                      </Form.Item>
                    </>
                    : ''}

              </Col>
              <Col xs={5} md={0} lg={6} xl={6} className='contentright'>
                {showExcessButtons && (selectedOptionExcess === 1 || selectedOptionExcess === 2 || selectedOptionExcess === 3) && (
                  <a className='nobold'>{resultExesos1 !== null ? `${resultExesos1}€` : '___€'}</a>
                )}
                <Popover content={exesoscontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>

            </Row>
            {showExcessButtons && (
              <Row className='verde'>
                <Col xs={0} lg={8} xl={8} className='contentleft paddtextsoleft'>
                </Col>
                <Col xs={18} lg={10} xl={10} className='contentleft'>
                  {selectedOptionExcess === 1
                    ? ''
                    : selectedOptionExcess === 2 || selectedOptionExcess === 3
                      ? <>
                        <Form.Item
                          name="ExesosPower2"
                          className='height'
                          rules={[
                            { required: true, message: '' },
                          ]}
                        ><a className='nobold paddingmiddletoright'>
                            P 2: {" "}
                            <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                              if (e.key === '-' || e.key === 'e') {
                                e.preventDefault();
                              }
                            }}
                              onChange={(e) =>
                                handlePowerChange(e, setPowerExesos2, rateExesos2, setResultExesos2, 'Exesos2Power')
                              } /> kW x &nbsp;</a>
                        </Form.Item>
                        <Form.Item
                          name="ExesosRate2"
                          className='height'
                          rules={[
                            { required: true, message: '' },
                          ]}
                        ><a className='nobold '>
                            <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                              if (e.key === '-' || e.key === 'e') {
                                e.preventDefault();
                              }
                            }}
                              onChange={(e) =>
                                handleRateChange(e, powerExesos2, setRateExesos2, setResultExesos2, 'Exesos2Rate')
                              } />€/kW día</a>
                        </Form.Item>
                      </>
                      : ''}
                </Col>
                <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                  {showExcessButtons && (selectedOptionExcess === 2 || selectedOptionExcess === 3) && (
                    <a className='nobold'>{resultExesos2 !== null ? `${resultExesos2}€` : '___€'}</a>
                  )}
                </Col>
              </Row>
            )}
            {showExcessButtons && selectedOptionExcess === 3 && (
              <Row className='verde'>
                <Col xs={0} lg={8} xl={8} className='contentleft paddtextsoleft'>
                </Col>
                <Col xs={18} lg={10} xl={10} className='contentleft'>
                  <Form.Item
                    name="ExesosPower3"
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  ><a className='nobold paddingmiddletoright'>
                      P 3: {" "}
                      <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                        onChange={(e) =>
                          handlePowerChange(e, setPowerExesos3, rateExesos3, setResultExesos3, 'Exesos3Power')
                        } /> kW x &nbsp;</a>
                  </Form.Item>
                  <Form.Item
                    name="ExesosRate3"
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  ><a className='nobold '>
                      <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                        onChange={(e) =>
                          handleRateChange(e, powerExesos3, setRateExesos3, setResultExesos3, 'Exesos3Rate')
                        } />€/kW día</a>
                  </Form.Item>
                </Col>
                <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                  <a className='nobold'>{resultExesos3 !== null ? `${resultExesos3}€` : '___€'}</a>
                </Col>
              </Row>
            )}

            {showExcessButtons && (
              <Row className={'verde'}>
                <Col xs={10} lg={8} xl={8} className='contentleft paddtextsoleft'>
                  <Button
                    className={`roundedbutton smaller-button marginnumberbuttons ${selectedOptionExcess === 1 ? 'selected' : ''}`}
                    onClick={() => handleOptionButtonClickExcess(1)}>1</Button>
                  <Button
                    className={`roundedbutton smaller-button marginnumberbuttons ${selectedOptionExcess === 2 ? 'selected' : ''}`}
                    onClick={() => handleOptionButtonClickExcess(2)}>2</Button>
                  <Button
                    className={`roundedbutton smaller-button marginnumberbuttons ${selectedOptionExcess === 3 ? 'selected' : ''}`}
                    onClick={() => handleOptionButtonClickExcess(3)}>3</Button>
                  <Button className={`roundedbutton smaller-button somarginnumberbuttons`}
                    onClick={handleLessButtonClickExcess}>-</Button>
                </Col>
              </Row>
            )}

            <Row className='blanco'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tienereactive} visible={messageReactive} placement="bottom">
                  <a className='negrita paddtextleft'>Energía Reactiva </a>
                  <Button className={`roundedbutton smaller-button`}
                    onClick={handlePlusButtonClickReactive}> + </Button>
                </Popover>
                {showReactive && (
                  <Button className={`roundedbutton smaller-button somarginnumberbuttons`}
                    onClick={handleLessButtonClickReactive}>-</Button>)}
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                {showReactive
                  ? <>
                    <Form.Item
                      name="ReactivaPower1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    ><a className='nobold paddingmiddletoright'>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                          onChange={(e) =>
                            handlePowerChange(e, setPowerReactiva1, rateReactiva1, setResultReactiva1, 'Reactiva1Power')
                          } /> kVArh x &nbsp;</a>
                    </Form.Item>
                    <Form.Item
                      name="ReactivaRate1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    >
                      <a className='nobold '>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                          onChange={(e) =>
                            handleRateChange(e, powerReactiva1, setRateReactiva1, setResultReactiva1, 'Reactiva1Rate')
                          } />€/kVArh</a>
                    </Form.Item>
                  </>
                  : ''}
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                {showReactive && (
                  <a className='nobold'>{resultReactiva1 !== null ? `${resultReactiva1}€` : '0€'}</a>
                )}
                <Popover content={reactivacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Finanaciación bono social fijo:</a>
              </Col>
              <Col xs={17} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'> {differenceInDays} días x 0,038455 €/día</a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{(differenceInDays * 0.038455).toFixed(2)}€</a>
                <Popover content={bonosocialcontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Impuesto sobre electricidad:</a>
              </Col>
              <Col xs={17} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'>2,5% {calculateTotal() !== null ? `s/${calculateTotal()} €` : '__'} </a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{calculateTotal() !== null ? `${(calculateTotal() * 0.025).toFixed(2)} €` : '__€'}</a>
                <Popover content={impuestocontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>

            <Row className='line'>
            </Row>
            <Row className='blanco'>
              <Col xs={16} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft green'>Total Energía</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='green'>{(calculateTotal() + calculateTotal() * 0.025).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={20} lg={8} xl={8} className='contentleft'>
              <Popover content={tieneother} visible={messageOthers} placement="bottom">
                <a className='negrita paddtextleft'>Servicios y otros conceptos   </a>
                <Button
                  className={`roundedbutton smaller-button`}
                  onClick={handleAddServicesAndOthersRow}
                >
                  +
                </Button>
                </Popover>
              </Col>
              <Col xs={0} lg={10} xl={10}>
              </Col>
              <Col xs={0} lg={6} xl={6} className='contentright'>
              </Col>
            </Row>
            {ServicesAndOthersRows.map((row) => (
              <Row key={row.rowKey}
                className='verde'
              >
                <Col xs={15} lg={8} xl={8} className='contentleft'>

                  <a className='nobold paddtextleft'>Monto total en Euros: {""} </a>

                </Col>
                <Col xs={0} lg={10} xl={10} className='contentleft'>
                </Col>
                <Col xs={9} lg={6} xl={6} className='contentright'>
                  <Form.Item
                    name={`ServiceAndOthersAmount_${row.rowKey}`}
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  >
                    <a className='nobold paddtextright'><input type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleServicesAndOthersInputChange(row.rowKey, e.target.value)}
                    ></input>€ </a>
                  </Form.Item>
                  <Button
                    className={`roundedbutton smaller-button paddtextrightnoquestion`}
                    onClick={() => handleDeleteServicesAndOthersRow(row.rowKey)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Alquiler equipos de medida:</a>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="equipodemedidaprice"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright '> {differenceInDays} días x &nbsp;
                    <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleequipodemedidapriceChange(e)}
                      value={equipodemedidaprice !== undefined ? equipodemedidaprice.toString() : ''}
                    />€/ día</a>
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{equipodemedidaprice ? (differenceInDays * equipodemedidaprice).toFixed(2) + '€' : '0€'}</a>
                <Popover content={alquilerquiposcontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>

            <Row className='line'>
            </Row>
            <Row className='blanco'>
              <Col xs={13} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft green'>Total Servicios y Otros Conceptos</a>
              </Col>
              <Col xs={5} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className=' green'>{(calculateTotalOthers() + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={18} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft '>Importe Total</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className=' '>{(calculateTotal() + calculateTotalOthers() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <Col xs={10} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft '>IVA</a>
              </Col>
              <Col xs={7} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'> {ivaValue} %</a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className=' '>{((calculateTotal() + calculateTotalOthers() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) * (ivaValue / 100)).toFixed(2)}€</a>
                <Popover content={ivacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>

            {ServicesAndOthersRows.length > 0 && (
              <Row className='blanco'>
                <Col span={8} className='contentleft'>
                  <a className=' paddtextleft '>IVA Servicios</a>
                </Col>
                <Col span={10} className='contentleft'>
                  <a className='nobold paddingmiddletoright'> 21 % s/{calculateTotalOthers().toFixed(2)}</a>
                </Col>
                <Col span={6} className='contentright'>
                  <a className=' '>{((calculateTotalOthers()) * (21 / 100)).toFixed(2)}€</a>
                  <Popover content={ivacontent} trigger="hover">
                    <QuestionCircleFilled className='question questioncomer paddtextright' />
                  </Popover>
                </Col>
              </Row>
            )}
            <Row className='line'>
            </Row>
            <Row className='verde'>
              <Col xs={18} lg={8} xl={8} className='contentleft'>
                <a className='green paddtextleft '>Total Importe Factura</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='green'>{((calculateTotal() + calculateTotalOthers() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) + ((calculateTotal() + calculateTotalOthers() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) * (ivaValue / 100))).toFixed(2)}€</a>
              </Col>
            </Row>
          </Col>
          <Row className='contenttocenter'>
            <Button type="primary" shape="round" className="buttoncont paddbutton" htmlType="submit"
              onClick={handleFormSubmit}>
              Continuar
            </Button>
          </Row>
          <Row className='contenttocenter'>
            <a className='greybutton paddtexttop' target="_blank" href='https://api.whatsapp.com/send/?phone=34655794576&text=&text=%C2%A1Hola%20Menor%20Consumo!%20necesito%20ayuda'>¿ No coincide con tu factura ?</a>
          </Row>

          <Modal
            title="Error en la validacion del formulario"
            open={errorModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalOk}
            footer={[
              <Button
                className='modalbutton'
                onClick={handleModalOk}
              >
                Continuar
              </Button>,
            ]}
          >
            <p>Complete el formulario y de manera correcta.</p>
          </Modal>
        </Card>
      </Form>
    </Container>
  );
}
export default CargarDatos2TD;