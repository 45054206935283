import '../assets/styles/ContratoDatosResidencial.css';
import { Input, Form, Card, Col, Row, Select } from 'antd';
import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function ContratoTarifaaccesoServiciocontratado(): JSX.Element {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { Option } = Select;
    const selectedUser = localStorage.getItem('selectedUsuario');

    const handleInputChange = (fieldName: string, value: string) => {
        form.setFieldsValue({ [fieldName]: value });
        const formValues = form.getFieldsValue();
        localStorage.setItem('ContratoTarifaacceso', JSON.stringify(formValues));
    };

    const handleFormSubmit = async () => {
        setFormSubmitted(true);
        try {
            await form.validateFields();
            navigate('/agradecimiento')
        } catch (error) {
            console.log('Please complete all required form fields.');
        }
    };

    useEffect(() => {
        if(selectedUser === 'comercial'){
            const prestoredFormData = localStorage.getItem('ContratoDatosComercial');
            if (prestoredFormData) {
                const prevparsedFormData = JSON.parse(prestoredFormData);
                form.setFieldsValue(prevparsedFormData);
            }
        } else if(selectedUser === 'residencial'){
            const prestoredFormData = localStorage.getItem('ContratoDatosResidencial');
            if (prestoredFormData) {
                const prevparsedFormData = JSON.parse(prestoredFormData);
                form.setFieldsValue(prevparsedFormData);
            }
        }
        
        const storedFormData = localStorage.getItem('ContratoTarifaacceso');
        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);
            form.setFieldsValue(parsedFormData);
        }
    }, [form]);

    return (
        <Container>
            <Form form={form} name="control-hooks">
                <Card className="card-residen-contrato" bordered={true}>
                    Solo unos datos mas...
                    <p className='datos'>Tarifa de Acceso y Servicio Contratado</p>
                    <Col className='separate'>
                        <Row className='col-inputs paddings'>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Tárifa"
                                    validateFirst
                                    className='margininput'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const lettersRegex = /^[A-Za-z]+$/;
                                                const minThreeLetters = value.length >= 3;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!lettersRegex.test(value) || !minThreeLetters) {
                                                    return Promise.reject(new Error('Tárifa no válida'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Tarifa"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Tarifa', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Modalidad"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const lettersRegex = /^[A-Za-z]+$/;
                                                const minThreeLetters = value.length >= 3;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!lettersRegex.test(value) || !minThreeLetters) {
                                                    return Promise.reject(new Error('Modalidad no válida'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Modalidad"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Modalidad', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>

                        <Row className='col-inputs paddings'>

                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Titular de la cuenta"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const lettersRegex = /^[A-Za-z\s]+$/;
                                                const minThreeLetters = value.length >= 3;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!lettersRegex.test(value) || !minThreeLetters) {
                                                    return Promise.reject(new Error('Nombre no válido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Titular de la cuenta"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Titular de la cuenta', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="TipoDocumento"
                                    validateFirst
                                    className='margininput nextto'
                                    rules={[{ required: true, message: '' }]}>
                                    <Select
                                        placeholder=""
                                        onChange={(value) => handleInputChange('TipoDocumento', value)}
                                        className='selection'
                                    >
                                        {selectedUser === 'residencial' ? (
                                            <>
                                                <Option value="DNI">DNI</Option>
                                                <Option value="NIF">NIF</Option>
                                                <Option value="NIE">NIE</Option>
                                            </>
                                        ) : (
                                            <>
                                                <Option value="CIF">CIF</Option>
                                                <Option value="NIF">NIF</Option>
                                                <Option value="NIE">NIE</Option>
                                            </>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="Documento"
                                    validateFirst
                                    className='margininput nextto'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const phoneRegex = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!phoneRegex.test(value)) {
                                                    return Promise.reject(new Error('Dato Inválido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Documento"
                                        className='input inputcontrat documento'
                                        onChange={(e) => handleInputChange('Documento', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='col-inputs paddings'>

                            <Col className='inputpadding'>
                                <Form.Item
                                    name="IBAN"
                                    validateFirst
                                    className='margininput'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const IBANRegex = /^[esES]{2}[0-9]{2}[A-Za-z0-9]{1,30}$/;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!IBANRegex.test(value)) {
                                                    return Promise.reject(new Error('IBAN no válido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="IBAN"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('IBAN', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Col>
                    <Button type="primary" shape="round" className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
                        Continuar
                    </Button>
                </Card>
            </Form>
        </Container>
    );
}

export default ContratoTarifaaccesoServiciocontratado;
