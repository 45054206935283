import '../assets/styles/NotFoundPage.css';
import React from 'react';
import { Button, Result, Row } from 'antd';

const NotFoundPage: React.FC = () => (
  <Result
    status="404"
    title="Error 404"
    subTitle="¡ OOPS !.... Lo sentimos, la página a la que intentas acceder no existe o no tienes permiso de acceso."
    extra={<Row className='alignmiddle'>
      <Button type="primary" href='/' className='button404'>Volver al inicio</Button>
      <Button type="primary" href='/login' className='button404'>Iniciar Sesión</Button>
    </Row>}
    className='page404'
  />
);

export default NotFoundPage;