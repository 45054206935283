import '../assets/styles/ServiceConsult.css';
import { Form, Card, Col, Row } from 'antd';
import { ThunderboltFilled, FireFilled, HomeFilled, ShopFilled, ClockCircleOutlined, SafetyCertificateOutlined, LineChartOutlined } from '@ant-design/icons';
import { Container } from 'react-bootstrap';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function ServiceConsult(): JSX.Element {
    const [size, setSize] = useState<SizeType>('middle');
    const [form] = Form.useForm();
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleServiceClick = (service: string) => {
        setSelectedService(service);
        form.setFieldsValue({ servicio: service });
    };

    const handleFormSubmit = () => {
        if (selectedService) {
            if (selectedService === 'electricidad') {
                navigate('./electricidad');
            } else if (selectedService === 'gas') {
                navigate('./gas');
            }
        }
    };

    return (
        <Container>
            <Form form={form} name="control-hooks">
                <Card className="cardconsult" bordered={true}>
                    ¿Que servicio quiere consultar?
                    <Row className='separate-consult'>
                        <Form.Item
                            name="servicio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Porfavor seleccione un tipo de servicio',
                                },
                            ]}
                        >
                            <Row gutter={16} justify="center" align="middle" className='content-align'>
                                <Col>
                                    <Card
                                        hoverable
                                        className={`selectcards ${selectedService === 'electricidad' ? 'clicked' : ''}`}
                                        onClick={() => handleServiceClick('electricidad')}
                                    >
                                        <div className='text'>
                                            <ThunderboltFilled />  Electricidad
                                        </div>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        hoverable
                                        className={`selectcards ${selectedService === 'gas' ? 'clicked' : ''}`}
                                        onClick={() => handleServiceClick('gas')}
                                    >
                                        <div className='text'>
                                            <FireFilled /> Gas
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Row>
                    <Button type="primary" shape="round" size={size} className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
                        Continuar
                    </Button>
                </Card>
            </Form>
        </Container>
    );
}

export default ServiceConsult;