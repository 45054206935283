import RecommendBox from '../components/RecommendBox';
import '../assets/styles/RecommendBox.css';
import { Container } from 'react-bootstrap';
import { Col, Row, Carousel } from 'antd';


function RecommendationPage() {

    return (
        <Container>
            <a className='titulin'>El ahorro depende de ti</a>
                {/* {typeof window !== 'undefined' && window.innerWidth < 385 ? (
                    <Carousel autoplay className='carousel'>
                        <div>
                        <RecommendBox />
                        </div>
                        <div>
                        <RecommendBox />
                        </div>
                        <div>
                        <RecommendBox />
                        </div>
                        <div>
                        <RecommendBox />
                        </div>
                    </Carousel>
                ) : ( */}
                    <Row className='middle'>
                        <Col>
                            <RecommendBox />
                        </Col>
                        <Col>
                            <RecommendBox />
                        </Col>
                        <Col>
                            <RecommendBox />
                        </Col>
                        <Col>
                            <RecommendBox />
                        </Col>
                    </Row>
                {/* )} */}
        </Container>
    );
}

export default RecommendationPage;