import '../assets/styles/CargarDatos2TD.css';
import { Form, Card, Col, Row, Popover, Input } from 'antd';
import { Container } from 'react-bootstrap';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
interface ChargeRow {
  rowKey: number;
  value: string;
  CargoNormAmount?: string;
}
interface DiscountRow {
  rowKey: number;
  value: string;
  DiscountAmount?: string;
}
interface ServicesAndOthersRow {
  rowKey: number;
  value: string;
}

function CargarDatosGas(): JSX.Element {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [discountRows, setDiscountRows] = useState<DiscountRow[]>([]);
  const [chargeRows, setChargeRows] = useState<ChargeRow[]>([]);
  const [ServicesAndOthersRows, setServicesAndOthersRows] = useState<ServicesAndOthersRow[]>([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [messageDisounts, setmessageDisounts] = useState(false);
  const [messageOthers, setmessageOthers] = useState(false);

  const [contadorprice, setequipodemedidaprice] = useState<number | undefined>(undefined);

  const [powerConsumo, setPowerConsumo] = useState('');

  const [rateConsumo, setRateConsumo] = useState('');

  const [resultConsumo, setResultConsumo] = useState<string | null>(null);
  const [resultFijo, setResultFijo] = useState<string | null>(null);

  const handleequipodemedidapriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(e.target.value);
    if (!isNaN(inputValue)) {
      setequipodemedidaprice(inputValue);
    };
  };

  const handleInputChange = (fieldName: string, value: string) => {
    form.setFieldsValue({ [fieldName]: value });
    const formValues = form.getFieldsValue();
    localStorage.setItem('formDataCargaGas', JSON.stringify(formValues));
  };

  const handleRateChangeForPuntaandValle = (
    e: React.ChangeEvent<HTMLInputElement>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    const newRate = e.target.value;
    const calculatedResult = Number(differenceInDays) * Number(newRate);
    const resultString = calculatedResult.toString();
    setResult(resultString);
    handleInputChange(fieldName, newRate);
  };
  const handleAddDiscountRow = (): void => {
    setmessageDisounts(false)
    setmessageOthers(true)
    if (discountRows.length < 1) {
      setDiscountRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
    }
  };

  const handleAddServicesAndOthersRow = (): void => {
    setmessageOthers(false)
    if (ServicesAndOthersRows.length < 1) {
      setServicesAndOthersRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
    }
  };
  const handleDeleteDiscountRow = (rowKey: number): void => {
    setDiscountRows((prevRows) => prevRows.filter((row) => row.rowKey !== rowKey));
  };

  const updateResult = (
    power: string,
    rate: string,
    setResult: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    const result = (parseFloat(power) || 0) * (parseFloat(rate) || 0);
    setResult(result.toFixed(2));
  };
  const handleDiscountInputChange = (rowKey: number, inputValue: string, inputType: string): void => {
    setDiscountRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.rowKey === rowKey ? { ...row, [inputType]: inputValue } : row
      );
      const fieldName = `discountInput_${rowKey}_${inputType}`;
      handleInputChange(fieldName, inputValue);

      return updatedRows;
    });
  };
  const handleServicesAndOthersInputChange = (rowKey: number, inputValue: string): void => {
    setServicesAndOthersRows((prevRows) => {
      return prevRows.map((row) =>
        row.rowKey === rowKey ? { ...row, value: inputValue } : row
      );
    });
  };
  const handleDeleteServicesAndOthersRow = (rowKey: number) => {
    setServicesAndOthersRows((prevRows) => prevRows.filter((row) => row.rowKey !== rowKey));
    form.setFieldsValue({
      [`ServiceAndOthersDescription_${rowKey}`]: undefined,
      [`ServiceAndOthersAmount_${rowKey}`]: undefined,
    });
  };
  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      localStorage.setItem('formDataCargaGas', JSON.stringify(formValues));
      navigate('/recomendacion');
    } catch (errorInfo) {
      console.error('Form incomplete:', errorInfo);
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
    console.log('Failed:', errorInfo);
    setErrorModalVisible(true);
  };
  const handleModalOk = () => {
    setErrorModalVisible(false);
  };
  const handlePowerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setPower: React.Dispatch<React.SetStateAction<string>>,
    rate: string,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    setPower(e.target.value);
    updateResult(e.target.value, rate, setResult);
    handleInputChange(fieldName, e.target.value);
  };
  const handleRateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    power: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    setRate(e.target.value);
    updateResult(power, e.target.value, setResult);
    handleInputChange(fieldName, e.target.value);
  };
  const updateTotalSum = () => {
    const valuesToSum = [
      resultConsumo, resultFijo
    ];
    const filteredValues = valuesToSum
      .filter(value => value !== null)
      .map(value => parseFloat(value as string))
      .filter(value => !isNaN(value));

    const sum = filteredValues.reduce((acc, currentValue) => acc + currentValue, 0);
    setTotalSum(sum);
  };
  const calculateTotal = (): number => {
    const totalWithoutDiscount = chargeRows.reduce((total, row) => {
      const amount = parseFloat(row.CargoNormAmount || '0');
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    const discountTotal = discountRows.reduce((total, row) => {
      const amount = parseFloat(row.DiscountAmount || '0');
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    const additionalTotalSum = totalSum || 0;
    const rawTotal = totalWithoutDiscount - discountTotal + additionalTotalSum;
    const formattedTotal = Math.max(parseFloat(rawTotal.toFixed(2)), 0);
    return formattedTotal;
  };
  const calculateTotalOthers = (): number => {
    const totalOthers = ServicesAndOthersRows.reduce((total, row) => total + parseFloat(row.value) || 0, 0);
    return totalOthers
  };
  useEffect(() => {
    updateTotalSum();
  }, [resultConsumo, resultFijo]);
  useEffect(() => {
    const selectedUsuario = localStorage.getItem('selectedUsuario')
    if (selectedUsuario == 'residencial') {
      const storedDataStringRes = localStorage.getItem('formDataElectricidadCarga Residencial');
      if (storedDataStringRes) {
        const storedData = JSON.parse(storedDataStringRes);
        const storedInicio = new Date(storedData.Inicio);
        const storedFin = new Date(storedData.final);
        const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
        const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        setDifferenceInDays(differenceInDays);
      }
    } else if (selectedUsuario == 'comercial') {
      const storedDataStringCom = localStorage.getItem('formDataElectricidadCarga Comercial');
      if (storedDataStringCom) {
        const storedData = JSON.parse(storedDataStringCom);
        const storedInicio = new Date(storedData.Inicio);
        const storedFin = new Date(storedData.final);
        const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
        const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        setDifferenceInDays(differenceInDays);
      }
    }
  }, []);
  useEffect(() => {
    try {
      const storedFormData = localStorage.getItem('formDataCargaGas');
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
        // form.setFieldsValue(parsedFormData);
      }
    } catch (error) {
      console.error('Error parsing stored form data:', error);
    }
  }, [form]);

  const tienediscount = (
    <div className='divguide'>
      <p>Tiene algun descuento?
        <button className='bottonno' onClick={() => {
          setmessageDisounts(false)
          setmessageOthers(true)
        }}>No</button>
      </p>
    </div>
  );
  const tieneother = (
    <div className='divguide'>
      <p>Tiene otros conceptos?
        <button className='bottonno' onClick={() => {
          setmessageOthers(false)
        }}>No</button>
      </p>
    </div>
  );
  const consumogascontent = (
    <div className='divguide'>
      <p>Precio que pagamos a la compañía comercializadora por el gas natural que consumimos.</p>
    </div>
  );
  const terminofijogascontent = (
    <div className='divguide'>
      <p>Importe que se paga por tener acceso a la red de distribución de gas.</p>
    </div>
  );
  const impuestocontent = (
    <div className='divguide'>
      <p>Impuesto estatal que grava el consumo de combustibles y carburantes.</p>
    </div>
  );
  const alquilercontadorcontent = (
    <div className='divguide'>
      <p>Cantidad mensual que se paga a la empresa eléctrica cuando el contador es propiedad de ésta.</p>
    </div>
  );
  const ivacontent = (
    <div className='divguide'>
      <p>El Impuesto sobre el Valor Añadido constituye la base del sistema español de imposición indirecta.</p>
    </div>
  );
  return (
    <Container>
      <Form form={form} name="control-hooks" onFinishFailed={onFinishFailed}
      >
        <Card className="cardtable" bordered={true}>
          <Col>
            <Row className='contenttocenter paddbottomtitle'>
              <Col xs={24} lg={16} xl={16}>
                <a className="titlecard">Busca tu factura y completa el siguiente formulario</a>
              </Col>

            </Row>
            <Row className='verde'>
              <a className='negrita paddtextleft'>Gas Natural</a>
            </Row>
            <Row className='blanco'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Consumo Gas:</a>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="PuntaPower"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>
                    <input
                      type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      value={powerConsumo}
                      onChange={(e) =>
                        handlePowerChange(e, setPowerConsumo, rateConsumo, setResultConsumo, 'ExesosPunta')
                      }
                    /> kWh
                  </a>
                </Form.Item>
                <Form.Item
                  name="PuntaRate"
                  className='height inlineinput'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold '>
                    &nbsp; x{" "}
                    <input
                      type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleRateChange(e, powerConsumo, setRateConsumo, setResultConsumo, 'ratePunta')}
                    />€/kWh
                  </a>
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                <a className='nobold'>{resultConsumo !== null ? `${resultConsumo}€` : '0€'}</a>
                <Popover content={consumogascontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Término Fijo Gas:</a>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft '>

                <Form.Item
                  name="ValleRate"
                  className='height paddingmiddletoright'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) => {
                        handleRateChangeForPuntaandValle(e, setResultFijo, 'rateValle')
                        setmessageDisounts(true)
                      }
                      }
                    ></input>€/ día</a>
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                <a className='nobold'>{resultFijo !== null ? `${Number(resultFijo).toFixed(2)}€` : '0€'}</a>
                <Popover content={terminofijogascontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>

            <Row className='blanco'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tienediscount} visible={messageDisounts} placement="right">
                  <a className='negrita paddtextleft'>Descuentos   </a>
                  <Button
                    className={`roundedbutton smaller-button`}
                    onClick={handleAddDiscountRow}
                  >
                    +
                  </Button>
                </Popover>
              </Col>
              <Col xs={0} lg={10} xl={10}>
              </Col>
              <Col xs={0} lg={6} xl={6} className='contentright'>
              </Col>
            </Row>
            {discountRows.map((row) => (
              <Row key={row.rowKey}
                className='blanco'
              >
                <Col xs={15} lg={8} xl={8} className='contentleft'>
                  <a className='nobold paddtextleft'>Monto total en Euros: {""} </a>
                </Col>
                <Col xs={0} lg={10} xl={10} className='contentleft'>

                </Col>
                <Col xs={9} lg={6} xl={6} className='contentright'>
                  <Form.Item
                    name={`DiscountAmount_${row.rowKey}`}
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  >
                    <a className='nobold paddtextright'>-<input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) => handleDiscountInputChange(row.rowKey, e.target.value, 'DiscountAmount')}
                    ></input>€ </a>
                  </Form.Item>
                  <Button
                    className={`roundedbutton smaller-button paddtextrightnoquestion`}
                    onClick={() => handleDeleteDiscountRow(row.rowKey)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}


            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Impuesto sobre Hidrocarburos:</a>
              </Col>
              <Col xs={15} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'>{parseFloat(powerConsumo) || powerConsumo === '0' ? `${powerConsumo} KWh x 0,002340€/KWh` : `0 KWh x 0,002340€/KWh`}</a>
              </Col>
              <Col xs={9} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{powerConsumo !== null ? `${(Number(powerConsumo) * 0.002340).toFixed(5)} €` : '__€'}</a>
                <Popover content={impuestocontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>

            <Row className='line'>
            </Row>
            <Row className='blanco'>
              <Col xs={15} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft green'>Total Energía</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={6} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='green'>{(calculateTotal() + (Number(powerConsumo) * 0.002340)).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tieneother} visible={messageOthers} placement="right">
                  <a className='negrita paddtextleft'>Servicios y otros conceptos   </a>
                  <Button
                    className={`roundedbutton smaller-button`}
                    onClick={handleAddServicesAndOthersRow}
                  >
                    +
                  </Button>
                </Popover>
              </Col>
              <Col xs={0} lg={10} xl={10}>
              </Col>
              <Col xs={0} lg={6} xl={6} className='contentright'>
              </Col>
            </Row>
            {ServicesAndOthersRows.map((row) => (
              <Row key={row.rowKey}
                className='verde'
              >
                <Col xs={15} lg={8} xl={8} className='contentleft'>

                  <a className='nobold paddtextleft'>Monto total en Euros: {""} </a>

                </Col>
                <Col xs={0} lg={10} xl={10} className='contentleft'>
                </Col>
                <Col xs={8} lg={6} xl={6} className='contentright'>
                  <Form.Item
                    name={`ServiceAndOthersAmount_${row.rowKey}`}
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  >
                    <a className='nobold paddtextright'><input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) => handleServicesAndOthersInputChange(row.rowKey, e.target.value)}
                    ></input>€ </a>
                  </Form.Item>
                  <Button
                    className={`roundedbutton smaller-button paddtextrightnoquestion`}
                    onClick={() => handleDeleteServicesAndOthersRow(row.rowKey)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Alquiler de contador:</a>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="equipodemedidaprice"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright '> {differenceInDays} días x &nbsp;
                    <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleequipodemedidapriceChange(e)}
                      value={contadorprice !== undefined ? contadorprice.toString() : ''}
                    />€/ día</a>
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{contadorprice ? (differenceInDays * contadorprice).toFixed(2) + '€' : '0€'}</a>
                <Popover content={alquilercontadorcontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='line'>
            </Row>
            <Row className='blanco'>
              <Col xs={14} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft green'>Total Servicios y Otros Conceptos</a>
              </Col>
              <Col xs={3} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={4} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className=' green'>{(calculateTotalOthers() + Number(contadorprice ? (differenceInDays * contadorprice) : '0')).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={16} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft '>Importe Total</a>
              </Col>
              <Col xs={1} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={4} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className=' '>{(calculateTotal() + calculateTotalOthers() + (Number(powerConsumo) * 0.002340) + Number(contadorprice ? (differenceInDays * contadorprice) : '0')).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <Col xs={8} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft '>IVA Gas</a>
              </Col>
              <Col xs={9} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'> 21 % s/{(calculateTotal() + (Number(powerConsumo) * 0.002340) + Number(contadorprice ? (differenceInDays * contadorprice) : '0')).toFixed(2)}</a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className=' '>{((calculateTotal() + (Number(powerConsumo) * 0.002340) + Number(contadorprice ? (differenceInDays * contadorprice) : '0')) * (21 / 100)).toFixed(2)}€</a>
                <Popover content={ivacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            {ServicesAndOthersRows.length > 0 && (
              <Row className='blanco'>
                <Col span={8} className='contentleft'>
                  <a className=' paddtextleft '>IVA Servicios</a>
                </Col>
                <Col span={10} className='contentleft'>
                  <a className='nobold paddingmiddletoright'> 21 % s/{calculateTotalOthers().toFixed(2)}</a>
                </Col>
                <Col span={6} className='contentright'>
                  <a className=' '>{((calculateTotalOthers()) * (21 / 100)).toFixed(2)}€</a>
                  <Popover content={ivacontent} trigger="hover">
                    <QuestionCircleFilled className='question questioncomer paddtextright' />
                  </Popover>
                </Col>
              </Row>
            )}
            <Row className='line'>
            </Row>
            <Row className='verde'>
              <Col xs={15} lg={8} xl={8} className='contentleft'>
                <a className='green paddtextleft '>Total Importe Factura</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={6} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='green'>{((calculateTotal() + (Number(powerConsumo) * 0.002340) + Number(contadorprice ? (differenceInDays * contadorprice) : '0')) + ((calculateTotal() + (Number(powerConsumo) * 0.002340) + Number(contadorprice ? (differenceInDays * contadorprice) : '0')) * (21 / 100)) + (calculateTotalOthers() + (calculateTotalOthers()) * (21 / 100))).toFixed(2)}€</a>
              </Col>
            </Row>
          </Col>
          <Row className='contenttocenter'>
            <Button type="primary" shape="round" className="buttoncont paddbutton" htmlType="submit" onClick={handleFormSubmit} >
              Continuar
            </Button>
          </Row>
          <Row className='contenttocenter'>
            <a className='greybutton paddtexttop' target="_blank" href='https://api.whatsapp.com/send/?phone=34655794576&text=&text=%C2%A1Hola%20Menor%20Consumo!%20necesito%20ayuda'>¿ No coincide con tu factura ?</a>
          </Row>
          <Modal
            title="Error en la validacion del formulario"
            open={errorModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalOk}
            footer={[
              <Button
                className='modalbutton'
                onClick={handleModalOk}
              >
                Continuar
              </Button>,
            ]}
          >
            <p>Complete el formulario y de manera correcta.</p>
          </Modal>
        </Card>
      </Form>
    </Container>
  );
}

export default CargarDatosGas;