import '../assets/styles/ElectricidadResidencialCarga.css';
import { Input, Form, Card, Col, Row } from 'antd';
import { Container } from 'react-bootstrap';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useState, useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import { Popover } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import axios from 'axios';
import Loading from '../components/Loading'

function ElectricidadResidencialCarga(): JSX.Element {
  const [size, setSize] = useState<SizeType>('middle');
  const [form] = Form.useForm();
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const storedFormData = localStorage.getItem('formDataElectricidadCarga Residencial');
  const parsedFormData = storedFormData ? JSON.parse(storedFormData) : {};
  const [poblacion, setPoblacion] = useState<string>(parsedFormData.Población || '');
  const [provincia, setProvincia] = useState<string>(parsedFormData.Provincia || '');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 449);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 449);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleInputChange = (fieldName: string, value: string | Dayjs | null) => {
    form.setFieldsValue({ [fieldName]: value });
    const values = form.getFieldsValue();

    localStorage.setItem('formDataElectricidadCarga Residencial', JSON.stringify(values));
  };


  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      await handleCodigoPostalChange(form.getFieldValue('Código Postal'));
      await form.validateFields();  
      navigate('./2.0TD');
    } catch (error) {
      console.error('Error handling Código Postal change:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCodigoPostalChange = async (value: string) => {
    try {
      const response = await axios.get(`https://api.zippopotam.us/es/${value}`);
      console.log(response.data);
      const { places } = response.data;

      if (places && places.length > 0) {
        const { "place name": placeName, state } = places[0];

        setPoblacion(state);
        setProvincia(placeName);

        form.setFieldsValue({
          Población: state,
          Provincia: placeName,
        });
      } else {
        setPoblacion('');
        setProvincia('');

        form.setFieldsValue({
          Población: '',
          Provincia: '',
        });
      }

    } catch (error) {
      console.error('Error fetching data for Código Postal:', error);
    }
  };
  useEffect(() => {
    const storedFormData = localStorage.getItem('formDataElectricidadCarga Residencial');

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.Inicio && moment(parsedFormData.Inicio).isValid()) {
        parsedFormData.Inicio = moment(parsedFormData.Inicio);
      }

      if (parsedFormData.final && moment(parsedFormData.final).isValid()) {
        parsedFormData.final = moment(parsedFormData.final);
      }

      form.setFieldsValue(parsedFormData);
    }
  }, [form]);

  useEffect(() => {
    const storedFormData = localStorage.getItem('formDataElectricidadCarga Residencial');
    const parsedFormData = storedFormData ? JSON.parse(storedFormData) : {};

    parsedFormData.Población = poblacion;
    parsedFormData.Provincia = provincia;

    localStorage.setItem('formDataElectricidadCarga Residencial', JSON.stringify(parsedFormData));
  }, [poblacion, provincia]);


  return (
    <Container>
      {loading && <Loading />}
      <Form form={form} name="control-hooks" disabled={loading}>
        <Card className="card-residen-luz" bordered={true}>
          Busca tu factura y completa el siguiente formulario
          <Col className='alignment'>
            <Row className='alignment-row'>
              <a className='texto cups'>CUPS:</a>
              <Form.Item
                name="CUPS"
                className='margininput'
                validateFirst
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const cupsRegex = /^ES\d{16,18}[A-Za-z]{2}$/;

                      if (!value) {
                        return Promise.reject(new Error('ingrese el CUPS'));
                      }

                      if (!cupsRegex.test(value)) {
                        return Promise.reject(new Error('CUPS Inválido'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="ES0123456789012345ZF"
                  className='input inputcup'
                  onChange={(e) => handleInputChange('CUPS', e.target.value)}
                />
              </Form.Item>
            </Row>
            <Row className='alignment-row'>
              <a className='texto'>Periodo Facturado:</a>
              <Col>
                <Form.Item name="Inicio" rules={[{ required: true, message: '' }]}>
                  <DatePicker
                    placeholder='Inicio'
                    className='input inputdat left inicial'
                    format="DD-MM-YYYY"
                    onChange={(inicioDate) => {
                      handleInputChange('Inicio', inicioDate);
                      form.setFieldsValue({ final: null });
                      form.setFieldsValue({ Inicio: inicioDate });
                    }}

                  />
                </Form.Item>
              </Col>
              {isWideScreen && (
              <Col>
                <a>a</a>
              </Col>
              )}
              <Col className='dateinf'>
                <Form.Item name="final" rules={[{ required: true, message: '' }]}>
                  <DatePicker
                    placeholder='Final'
                    className='input inputdat right final'
                    disabledDate={(currentDate) => {
                      const inicioDate = form.getFieldValue('Inicio');
                      return currentDate && currentDate.isBefore(inicioDate, 'day');
                    }}
                    onChange={(finalDate) => handleInputChange('final', finalDate)}
                    value={form.getFieldValue('final')}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className='alignment-row'>
              <a className='texto codpost'>Código Postal:</a>
              <Form.Item
                name="Código Postal"
                className='margininput'
                validateFirst
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      // if (!formSubmitted) {
                      //   return Promise.resolve();
                      // }
                      const Regex = /^[0-9]{5}$/;

                      if (!Regex.test(value)) {
                        setPoblacion('');
                        setProvincia('');
                        form.setFieldsValue({
                          Población: '',
                          Provincia: '',
                        });
                        return Promise.reject(new Error('Código Postal Inválido'));
                      }

                      try {
                        const response = await axios.get(`https://api.zippopotam.us/es/${value}`);
                        console.log(response.data);
                        const { places } = response.data;

                        if (places && places.length > 0) {
                          const { "place name": placeName, state } = places[0];

                          setPoblacion(state);
                          setProvincia(placeName);

                          form.setFieldsValue({
                            Población: state,
                            Provincia: placeName,
                          });


                          return Promise.resolve();
                        } else {
                          setPoblacion('');
                          setProvincia('');

                          form.setFieldsValue({
                            Población: '',
                            Provincia: '',
                          });

                          return Promise.reject('No place found for Código Postal: ' + value);
                        }
                      } catch (error) {
                        console.error('Error fetching data for Código Postal:', error);
                        return Promise.reject('Código Postal Inválido');
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className='input inputcup'
                  onChange={(e) => {
                    handleInputChange('Código Postal', e.target.value);
                    handleCodigoPostalChange(e.target.value);
                    setErrorMsg(null);
                  }}
                />
              </Form.Item>

              {errorMsg && <div className="error-message">{errorMsg}</div>}
            </Row>

            <Row className='alignment-row'>
              <a className='texto provinc'>Provincia:</a>
              <Form.Item
                name="Provincia"
                className='margininput'
                validateFirst
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const lettersRegex = /^[A-Za-z \s]+$/;
                      const minThreeLetters = value.length >= 3;

                      if (!value) {
                        return Promise.reject(new Error(''));
                      }

                      if (!lettersRegex.test(value) || !minThreeLetters) {
                        return Promise.reject(new Error('Provincia no válida'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className='input inputcup uneditable'
                  value={provincia}
                  readOnly
                />
              </Form.Item>
            </Row>

            <Row className='alignment-row'>
              <a className='texto pobla'>Población:</a>
              <Form.Item
                name="Población"
                className='margininput'
                validateFirst
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const lettersRegex = /^[A-Za-z -\s]+$/;
                      const minThreeLetters = value.length >= 3;

                      if (!value) {
                        return Promise.reject(new Error(''));
                      }

                      if (!lettersRegex.test(value) || !minThreeLetters) {
                        return Promise.reject(new Error('Población no válida'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className='input inputcup uneditable'
                  value={poblacion}
                  readOnly
                />
              </Form.Item>
            </Row>
            <Row className='alignment-row'>
              <a className='texto texto-cortado'>Direccion del punto de suministro:</a>
              <Form.Item
                name="Dirección"
                className='margininput'
                validateFirst
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const lettersRegex = /^[A-Za-z\s]+$/;
                      const minThreeLetters = value.length >= 3;

                      if (!value) {
                        return Promise.reject(new Error(''));
                      }

                      if (!lettersRegex.test(value) || !minThreeLetters) {
                        return Promise.reject(new Error(''));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className='input inputdir'
                  onChange={(e) => handleInputChange('Dirección', e.target.value)}
                />
              </Form.Item>
            </Row>

          </Col>
          <Button type="primary" shape="round" size={size} className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
            Continuar
          </Button>
        </Card>
      </Form>
    </Container>
  );
}

export default ElectricidadResidencialCarga;
