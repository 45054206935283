import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import BackWithImage from './components/BackgroundWithImage';
import Login from './pages/Login';
import Signin from './pages/Signin';
import MainMenu from './pages/MainMenu';
import './App.css'
import Residential from './pages/Residential';
import Commercial from './pages/Commercial';
import ServiceConsult from './pages/ServiceConsult';
import ElectricityConsult from './pages/ElectricityConsult';
import GasConsult from './pages/GasConsult';
import BillLoad from './pages/BillLoad';
import ElectricidadResidencialCarga from './pages/ElectricidadResidencialCarga';
import ElectricidadComercialCarga from './pages/ElectricidadComercialCarga';
import GasCarga from './pages/GasCarga';
import ContratoDatosResidencial from './pages/ContratoDatosResidencial';
import ContratoDatosComercial from './pages/ContratoDatosComercial';
// import ContratoDatosSuministro from './pages/ContratoDatosSuministro';
import ContratoTarifaaccesoServiciocontratado from './pages/ContratoTarifaaccesoServiciocontratado';
import Agradecimiento from './pages/Agradecimiento';
import RecommendationPage from './pages/RecommendationPage';
import CargarDatos2TD from './pages/CargarDatos2TD';
import CargarDatos3o6TD from './pages/CargarDatos3o6TD';
import CargarDatosGas from './pages/CargarDatosGas';
import CargarDocumento from './pages/CargarDocumento';
import { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import NotFoundPage from './components/NotFoundPage';
import PrivateRoute from './utils/PrivateRoute';
import SigninRoute from './utils/SigninRoute';

// @ts-ignore
import ReactWhatsappButton from 'react-whatsapp-button';





function RouterWrapper() {
  const navigate = useNavigate();
  const selectedUsuario = localStorage.getItem('selectedUsuario');
  const selectedService = localStorage.getItem('selectedService')

  useEffect(() => {
    if (selectedUsuario === 'residencial' && window.location.pathname.includes('comercial')) {
      navigate('/404');
    } else if (selectedUsuario === 'comercial' && window.location.pathname.includes('residencial')) {
      navigate('/404');
    }
    if (selectedService === 'electricidad' && window.location.pathname.includes('gas')) {
      navigate('/404');
    } else if (selectedService === 'gas' && window.location.pathname.includes('electricidad')) {
      navigate('/404');
    }
  }, [selectedUsuario, selectedService, navigate]);


  return null;
}
function App() {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 3 * 60 * 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const storedTimestamp = localStorage.getItem('timestamp');
    if (storedTimestamp) {
      const timestampDiff = new Date().getTime() - parseInt(storedTimestamp, 10);
      const horasEnMillis = 24 * 60 * 60 * 1000;

      if (timestampDiff >= horasEnMillis) {
        localStorage.clear();
      }
    }
  }, []);

  const message = "¡Hola Menor Consumo! necesito ayuda";

  return (
    <Router>
      <div className="App">
        <BackWithImage />
        <ReactWhatsappButton
          message={message}
          countryCode="34"
          phoneNumber="655794576"
          animated
          className="whatsappbutton"
        />
        <RouterWrapper />
        <Routes>
          <Route path='/' element={<MainMenu />} />

          <Route path='/login' element={<Login />} />
          <Route path='/signin' element={<SigninRoute requiredRole = "ADMIN" children = {<Signin/>} />} />

          <Route path='/servicioaconsultar' element={<PrivateRoute children ={<ServiceConsult />}/>} />
          <Route path='/servicioaconsultar/electricidad' element={<PrivateRoute children ={<ElectricityConsult />} />}/>
          <Route path='/servicioaconsultar/gas' element={<PrivateRoute children ={<GasConsult />} />}/>

          <Route path='/residencial' element={<Residential />} />
          <Route path='/comercial' element={<Commercial />} />
          <Route path='/factura' element={<BillLoad />} />
          <Route path='/factura/cargardocumento' element={<CargarDocumento />} />
          <Route path='/residencial/electricidad' element={<ElectricidadResidencialCarga />} />
          <Route path='/comercial/electricidad' element={<ElectricidadComercialCarga />} />
          <Route path='/residencial/gas' element={<GasCarga />} />
          <Route path='/comercial/gas' element={<GasCarga />} />
          <Route path='/residencial/electricidad/2.0TD' element={<CargarDatos2TD />} />
          <Route path='/comercial/electricidad/2.0TD' element={<CargarDatos2TD />} />\
          <Route path='/comercial/electricidad/3.0TD' element={<CargarDatos3o6TD />} />
          <Route path='/comercial/electricidad/6.1TD' element={<CargarDatos3o6TD />} />
          <Route path='/residencial/gas/carga' element={<CargarDatosGas />} />
          <Route path='/comercial/gas/carga' element={<CargarDatosGas />} />

          <Route path='/recomendacion' element={<RecommendationPage />} />

          <Route path='/residencial/contrato/cliente' element={<ContratoDatosResidencial />} />
          <Route path='/comercial/contrato/cliente' element={<ContratoDatosComercial />} />
          {/* <Route path='/residencial/contrato/suministro' element={<ContratoDatosSuministro/>}/>
            <Route path='/comercial/contrato/suministro' element={<ContratoDatosSuministro/>}/> */}
          <Route path='/residencial/contrato/tarifaacceso' element={<ContratoTarifaaccesoServiciocontratado />} />
          <Route path='/comercial/contrato/tarifaacceso' element={<ContratoTarifaaccesoServiciocontratado />} />

          <Route path='/agradecimiento' element={<Agradecimiento />} />

          <Route path='*' element={<NotFoundPage />} />

        </Routes>
        <Modal
          title="¿Estas teniendo complicaciones?"
          open={showModal}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={[
            <Button
              className='modalbutton'
              onClick={handleCloseModal}
            >
              Continuar
            </Button>,
          ]}
        >
          <p>Recuerda que puedes contactarnos en cualquier momento.</p>
        </Modal>
      </div>
    </Router>
  );
}

export default App;
